import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import CustomAvatar from "../../components/Common/Avatar.js";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
} from "components/Common/Utils";
import Avatar from "react-avatar";
import { useParams } from "react-router-dom";
import moment from "moment";

import { format } from "date-fns";
import StarComponent from "./StarComponent";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import "./CustomCarduser.css";
import { DetailsOutlined, Visibility } from "@material-ui/icons";
import BucketList from "./BucketList";
import UserFollowerListTable from "./UserFollowerListTable";
import BucketListTable from "./BucketListTable";
import ReviewListTable from "./ReviewListTable";
import EventFollowingListTable from "./EventFollowingListTable";
import UserCheckinTable from "./UserCheckinTable.js";
import SubscriptionTablelist from "./SubsriptionTablelist";
import VenueFollowingListTable from "./VenueFollowingListTable";
import UserFollowingListTable from "./UserFollowingListTable";
import ClaimsListTable from "./ClaimsListTable";
import NotificationListTable from "./NotificationListTable";
import OutingsListTable from "./OutingsListTable.js";
import RingMembership from "./RingMembership.js";
import EventUserDetails from "./EventUserDetails.js";
import PromoterCricle from "./PromoterCricle.js";
import AddPromoterMangement from "./AddPromoterMangement.js";
import ComplimentMemberShip from "./ComplimentMemberShip.js";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function Userdetails() {
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [venueData, setData] = useState([]);
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(true);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const location1 = useLocation();
  const [model, setModel] = useState(false);
  const [offer, setOffer] = useState();
  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    detailIcon: {
      color: "blue",
    },
    roundImage: {
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },
  });

  const classes = useStyles();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const UserFetchDetail = async () => {
    setLoader(false);
    postRequest(`admin/user/detail`, { userId: `${id}` }).then((data) => {
      let userdata = data.data;

      console.log({ userdata });
      let status = data.status;
      setData(userdata);

      if (status == 1) {
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    UserFetchDetail();
  }, []);
  const toggleModel = () => {
    setModel(!model);
  };
  const closeBucketModal = () => {
    setModel(false);
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    countryCode,
    image,
    phone,
    email,
  } = venueData || {};

  const initials =
    venueData?.firstName?.charAt(0).toUpperCase() +
    venueData?.lastName?.charAt(0).toUpperCase();
  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="10">
            <div className="page-title-box">
              <h4>User Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">User Management</li>:
                <li className="breadcrumb-item">User details</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.back()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>

          {loader ? (
            <CardBody style={{ height: "100px" }}>
              <Spinner
                color="info"
                style={{
                  height: "4rem",
                  width: "4rem",
                  position: "absolute",
                  left: "50%",
                }}
              >
                Loading...
              </Spinner>
            </CardBody>
          ) : (
            <>
              <Col md="12">
                <Row>
                  <Card className="custom-card">
                    <div
                      className="cover-image"
                      style={{ backgroundColor: "rgb(29, 155, 240)" }}
                    ></div>
                    <div className="profile-image2">
                      {venueData?.image ? (
                        <img
                          src={image}
                          onClick={() => showLightBox(image)}
                          alt="User Avatar"
                        />
                      ) : (
                        // <Avatar
                        //   name={`${first_name} ${last_name}`}
                        //   src={image}
                        //   round={true}
                        //   size="74"
                        //   textSizeRatio={2}
                        //   // className="profile-image2"
                        // >
                        //   {initials}
                        // </Avatar>
                        <CustomAvatar
                          iconSize="74"
                          name={
                            firstName + lastName == ""
                              ? "*"
                              : `${firstName} ${lastName} `
                          }
                        />
                      )}
                    </div>

                    <div className="row my-2 py-2">
                      <div
                        className="userinfo col-md-12 "
                        style={{ paddingLeft: "20px" }}
                      >
                        <br></br>

                        <div className="fst">
                          <h3>
                            {firstName} {lastName}
                            {name}
                          </h3>
                        </div>

                        <div className="row">
                          <div className="col-md-4 fst py-1">
                            <p>
                              DOB: <span> {dateOfBirth}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Gender: <span> {gender}</span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              phone:
                              <span>
                                {countryCode} {phone}
                              </span>
                            </p>
                          </div>
                          <div className="col-md-4 fst py-1">
                            <p>
                              Email: <span> {email}</span>
                            </p>
                          </div>
                                                </div>
                      </div>
                    </div>
                  </Card>
                </Row>
              </Col>
              <Col md="12" style={{ marginLeft: "inherit" }}>
                <div
                  id="exTab2"
                  className="twitter-like-tabs2"
                  style={{
                    background: "#ffff",
                    marginLeft: "25px",
                    overflowX: "scroll",
                  }}
                >
                
                </div>
              </Col>

          
            </>
          )}
        </Row>

      </React.Fragment>
    
    </>
  );
}
