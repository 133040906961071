import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import {
  postRequest,
  putRequest,
  deleteRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
  pushUpdates,
  postRequestForm,
  customValidator,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "components/Common/FileUpload.js";
export default function CreateMenu() {
  const [categoryData, setCategoryData] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [CategoryList, setCategoryList] = useState([]);

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const AddMenu = async (e) => {
    e.preventDefault();

    setStartLoader(true);

    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);


      const selectedImageRes = await postRequestForm(
        "image/upload",
        selectedImageForm
      );

      if (selectedImageRes?.data?.url) {
        toast.success("Image uploaded successfully!");
        setStartLoader(false);
      } else {
        console.error("Image URL not found in the response.");
        toast.error("Image URL not found in the response.");
        setStartLoader(false);
      }
   
    setLoading(true);
    let payload = {
      ...categoryData,
      image:  selectedImageRes.data.url,
    };

    const response = await putRequest("cuisine/add", payload);
    setLoading(false);

    if (response.status) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/menu-list");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };

  const fetchCategoryList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("cuisine/cat/lists", param);
    if (category.status) {
      const options = category.data?.map((item) => {
        return { id: item._id, name: item.title };
      });
      console.log({ options });
      setCategoryList(options);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Menu Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Menu Management</li>:{" "}
              <li className="breadcrumb-item">Menu Create</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Menu Create </h4>
                <Form onSubmit={AddMenu}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              required
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              rows="4"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Price (Rs)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("price", e.target.value)
                              }
                              name="price"
                              min={0}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <select
                              required
                              onChange={(e) =>
                                updateValue("cuisineCatId", e.target.value)
                              }
                              class="form-select"
                              name="cuisineCatId"
                            >
                              <option value="" disabled>
                                Select Category
                              </option>
                              {CategoryList.map((Category) => (
                                <option
                                  key={Category?.value}
                                  value={Category?.value}
                                >
                                  {Category?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Images
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              multiple
                              required
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <div className="preview-images">
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                    margin: "5px",
                                  }}
                                >
                                  <img
                                    src={previewImage}
                                    onClick={() => showLightBox(previewImage)}
                                    style={{ width: "100px", height: "100px" }}
                                    alt={"Preview"}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
