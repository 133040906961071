import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner,Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import VenueModal from "../../components/Modal/VenueModalOrg.js";

export default function CreateDiscount() {
  const [discountData, setDiscountData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [discounttypeList, setDiscounttypeList] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [model, setModel] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (id, name) => {
    setVenueId(id);
    setVenueText(name);
    closeModal();
  };

  const Creatediscount = async (e) => {
    e.preventDefault();
    if (!discountData.title) {
      toast.error("Title is required");
      return;
    }

    if (!discountData.description) {
      toast.error("Please enter description");
      return;
    }

    setLoading(true);

    const response = await postRequest("coupon/add", { ...discountData });
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/offer");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...discountData };
    list[index] = value;
    setDiscountData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Offer Management</li>:{" "}
              <li className="breadcrumb-item">Create Offer</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Discount</h4>
                <Form onSubmit={Creatediscount}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={discountData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={discountData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Discount Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              required
                              name="type"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>

                              <option value={"percentage"}>Percentage</option>
                              <option value={"fixed"}>fixed</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("value", e.target.value)
                              }
                              value={discountData?.value}
                              name="value"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Code
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("code", e.target.value)
                              }
                              value={discountData?.code}
                              name="code"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Min Amount{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("minAmount", e.target.value)
                              }
                              min={0}
                              value={discountData?.minAmount}
                              name="minAmount"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Max Discount{" "}
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("maxDiscount", e.target.value)
                              }
                              min={0}
                              value={discountData?.maxDiscount}
                              name="maxDiscount"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("startDate", e.target.value)
                              }
                              value={discountData?.startDate}
                              name="startDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("endDate", e.target.value)
                              }
                              value={discountData?.endDate}
                              name="endDate"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
    </React.Fragment>
  );
}
