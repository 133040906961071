import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import { useHistory } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import countries from "./countries.json";
import {
  postRequestForm,
  customValidator,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import { canvasPreview } from "pages/VenueManagement/canvasPreview";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect";
import FileUpload from "../../components/Common/FileUpload";
import { set } from "lodash";

export default function CreateBusiness() {
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");

  const [businessData, setBusinessData] = useState({
    name: "",
    about: "",
    phone: "",
    email: "",
    password: "",
    website: "",

    selectedCover: "",
  });
  const [tempLogo, setTempLogo] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedCover, setSelectedCover] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);

  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const blobUrlRef = useRef("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);
  let history = useHistory();

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);    
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );
  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      const file = new File([blob], businessData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const showLightBox = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };

  const Createbusiness = async (e) => {
    e.preventDefault();
    businessData.selectedLogo = selectedLogo;
    businessData.selectedCover = selectedCover;
    const validator = customValidator(
      [
        {
          field: "name",
          errorMsg: "Please enter name",
        },
        {
          field: "about",
          errorMsg: "Please enter about",
        },
        {
          field: "phone",
          errorMsg: "Please enter phone",
        },
        // {
        //   field: "password",
        //   type: "password",
        //   errorMsg: "Please enter password",
        // },
        // {
        //   field: "website",
        //   errorMsg: "Please enter website",
        // },
        {
          field: "selectedLogo",
          errorMsg: "Please select logo",
        },
        {
          field: "selectedCover",
          errorMsg: "Please select cover",
        },
      ],
      businessData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    let phone = businessData?.phone?.trim();

    // check phn number lenght is 8-10 digit
    if (phone) {
      // if (!businessData?.country_code) {
      //   toast.error("Please select country code");
      //   return;
      // }

      // phone number start with +971 by default
       


      if (phone?.length < 6 || phone?.length > 10) {
        toast.error("Please enter valid phone number 6-10 Digit");
        return;
      }
      // // check phone number is valid or not
      // if (isNaN(phone)) {
      //   toast.error("Please enter valid phone number");
      //   return;
      // }
      // also check - + sign is not allowed
      if (phone.includes("+")) {
        toast.error("Please remove + sign from phone number");
        return;
      }

      if (phone.includes("-")) {
        toast.error("Please remove - sign from phone number");
        return;
      }
    }

    if (businessData?.email) {
      // Define a regular expression for email validation
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const isValidEmail = emailRegex.test(businessData.email);

      if (!isValidEmail) {
        toast.error("Please enter a valid email");
        return;
      }
    }

    const formData = new FormData();

    setStartLoader(true);
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", remoteLogo);
    const selectedImageRes = await postRequestForm(
      "image/upload",
      selectedImageForm
    );
    const selectedImageForm2 = new FormData();
    selectedImageForm2.append("image", selectedCover);
    const selectedImageRes2 = await postRequestForm(
      "image/upload",
      selectedImageForm2
    );
    setStartLoader(false);

    formData.append("name", businessData.name);
    formData.append("about", businessData.about);

    if (phone) {
      formData.append("phone", `${phone}`);
    }

    formData.append("email", businessData.email);
    formData.append("password", businessData.password);
    formData.append("website", businessData.website);
    if (selectedImageRes?.data?.url) {
      formData.append("logo", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }
    if (selectedImageRes2?.data?.url) {
      formData.append("cover", selectedImageRes2.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }

    setLoading(true);
    const response = await postRequestForm("business/add", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/business");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValuePhone = (value) => {
    // check if value is number

    // if(isNaN(value)){
    //   return;
    // }

    // if(value.length > 10){
    //   return;
    // }

    const list = { ...businessData };
    list["phone"] = value;
    setBusinessData(list);
  };

  const updateValue = (index, value) => {
    const list = { ...businessData };
    list[index] = value;
    setBusinessData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Business Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Business Management</li>:{" "}
              <li className="breadcrumb-item">Create Business</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Business </h4>
                <Form onSubmit={Createbusiness}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={businessData?.name}
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              value={businessData?.about}
                              onChange={(e) =>
                                updateValue("about", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Country Code
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("country_code", e.target.value)
                              }
                              value={businessData?.country_code}
                              name="country"
                              className="form-select"
                            >
                              <option value="">Select country code</option>
                              {countries.map((country) => (
                                <option
                                  key={country.phoneCode}
                                  value={country.phoneCode}
                                >
                                  {country.name} ({country.phoneCode})
                                </option>
                              ))}
                            </select>
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone (Without country code)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={businessData?.phone || ""}
                              onChange={(e) => updateValuePhone(e.target.value)}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              value={businessData?.email}
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="password"
                              onChange={(e) =>
                                updateValue("password", e.target.value)
                              }
                              name="password"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-6">
                            <span
                              style={{
                                padding: "0",
                                border: "none",
                                background: "none",
                              }}
                              className="input-group-text"
                              
                            >
                              <input
                                type={showPassword ? "text" : "password"}
                                value={businessData?.password}
                                onChange={(e) =>
                                  updateValue("password", e.target.value)
                                }
                                name="password"
                                className="form-control"
                              />
                              <i
                                style={{ marginLeft: 15 }}
                                onClick={() => setShowPassword(!showPassword)}
                                className={`fa ${
                                  showPassword ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </span>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={businessData?.website}
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />

                            {previewLogo && selectedLogo ? (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img ref={imgRef} src={previewLogo} />
                              </ReactCrop>
                            ) : previewLogo ? (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={previewLogo}
                                onClick={() => showLightBox2(previewLogo)}
                              />
                            ) : null}

                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                onClick={() => showLightBox(previewCover)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
    </React.Fragment>
  );
}
