
import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import {
  postRequest,
  putRequest,
  deleteRequest,
  deleteConfirmationWords,
  convertTimeToFormattedString,
  pushUpdates,
  postRequestForm,
  customValidator,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "components/Common/FileUpload.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";


export default function UpdatedCategoryManagement() {
  const [categoryData, setCategoryData] = useState();
  const [previewImage, setPreviewImage] = useState([]);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [FeatureList, setFeatureList] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState([]);

  let history = useHistory();


  const { id } = useParams()
  const location = useLocation();
  const { row } = location.state || {};

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      // setPreviewImage(reader.result);
      RoomImageUpload();
    };
    reader.readAsDataURL(selectedImage);

  }, [selectedImage]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };




  const RoomImageUpload = async (e) => {
    setStartLoader(true);

    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);

    try {
      const selectedImageRes = await postRequestForm(
        "image/upload",
        selectedImageForm
      );

      if (selectedImageRes?.data?.url) {
        setPreviewImage((prevImages) => [...prevImages, selectedImageRes.data.url]);
        toast.success("Image uploaded successfully!");
      } else {
        console.error("Image URL not found in the response.");
        toast.error("Image URL not found in the response.");
      }
    } catch (error) {
      toast.error("Error uploading image. Please try again.");
    } finally {
      setStartLoader(false);
    }
  };

  const removeImage = (index) => {

    const updatedPreviewImages = previewImage.filter((_, i) => i !== index);

    setPreviewImage(updatedPreviewImages);
  };





  const Createcategory = async (e) => {
    e.preventDefault();

    setLoading(true);

    let payload = {
      ...categoryData,
      hotelId: "667d14fd858edc64ebe7dc19",
      facility: SelectedCategory,
      images: previewImage,
      roomCategoryId:id

    }
    const response = await putRequest("room/category/update", payload);
    setLoading(false);

    if (response.status) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/category");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };

  const fetchFeatureList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("feature/lists", param);
    if (category.status) {
      const options = category.data?.map((item) => {
        return { id: item._id, name: item.title };
      });

      setFeatureList(options);

    }
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };




  useEffect(() => {
    fetchFeatureList()
  }, [])


  useEffect(() => {
    setPreviewImage(row?.images);
    setCategoryData(row);
    console.log({ row })
  }, [row])

  const defaultCategory = useMemo(() => {
    return FeatureList
      ?.filter((item,index) => row?.facility?.includes(item.name)) || [];
  }, [row, FeatureList]);



  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Room Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Update Management</li>:{" "}
              <li className="breadcrumb-item">Update Category</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Category </h4>
                <Form onSubmit={Createcategory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Room Type
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              value={categoryData?.type}
                              required
                              name="type"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Room Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={categoryData?.description}
                              name="description"
                              className="form-control"
                              rows="4"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Room Price Per Night (Rs)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("pricePerNight", e.target.value)
                              }
                              value={categoryData?.pricePerNight}
                              name="pricePerNight"
                              min={0}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Number Of Adult
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              value={categoryData?.adult}
                              onChange={(e) =>
                                updateValue("adult", e.target.value)
                              }
                              name="adult"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Number Of Children
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              onChange={(e) =>
                                updateValue("children", e.target.value)
                              }
                              value={categoryData?.children}
                              name="children"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Room size
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              value={categoryData?.size}
                              onChange={(e) =>
                                updateValue("size", e.target.value)
                              }
                              name="size"
                              min={0}
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Number Of Rooms
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              value={categoryData?.numberOfRooms}
                              onChange={(e) =>
                                updateValue("numberOfRooms", e.target.value)
                              }
                              name="numberOfRooms"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Room Series
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              min={0}
                              value={categoryData?.roomSeries}
                              onChange={(e) =>
                                updateValue("roomSeries", e.target.value)
                              }
                              name="roomSeries"
                              className="form-control"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Number Of Beds
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("beds", e.target.value)
                              }
                              name="beds"
                              value={categoryData?.beds}
                           
                              className="form-control"
                              required
                            />
                          </div>
                        </div>



                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Feature
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={FeatureList}
                              onSelect={msUpdateCategory}
                              selectedValues={defaultCategory}
                              placeholder="Select Feature"
                            />
                          </div>
                        </div>





                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Images
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              multiple
                    
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            <div className="preview-images">
                              {previewImage?.map((image, index) => (
                                <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '5px' }}>
                                  <img
                                    src={image}
                                    onClick={() => showLightBox(image)}
                                    style={{ width: "100px", height: "100px" }}
                                    alt={`Preview ${index + 1}`}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeImage(index)}
                                    style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      background: 'red',
                                      color: 'white',
                                      border: 'none',
                                      borderRadius: '50%',
                                      cursor: 'pointer'
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
