import PropTypes from "prop-types";
import React, { useState } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

// Reactstrap
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import megamenuImg from "../../assets/images/megamenu-img.png"
import logoLightPng from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";
import logo from "../../assets/images/logo.png";
import logoSmall from "../../assets/images/logo-sms.jpeg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const [createmenu, setCreateMenu] = useState(false);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          {/* <img src={logoSmall} alt='logo'  /> */}
          <div className="d-flex">
            <div
              className="navbar-brand-box"
              style={
                props.currentPage == "eventOrganizerDetails" ||
                props.currentPage == "businessDetails"
                  ? { background: "inherit" }
                  : null
              }
            >
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  {/* <h3>1212</h3> */}
                  <img
                    src={logoSmall}
                    alt="logo"
                    style={{ marginLeft: "-10px",maxHeight:"100px",maxWidth:"100px"  }}
                  />
                </span>
                <span className="logo-lg">
                  {/* <h3>Whosin</h3> */}
                  <img
                    src={logoSmall}
                    alt="logo"
                    style={{ marginLeft: "-10px" ,maxHeight:"100px",maxWidth:"100px" }}
                  />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    src={logoSmall}
                    alt="logo"
                    style={{ marginLeft: "-10px",maxHeight:"100px",maxWidth:"100px"  }}
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={logoSmall}
                    alt="logo"
                    style={{ marginLeft: "-20px",maxHeight:"70px",maxWidth:"100px"  }}
                  />
                </span>
              </Link>
            </div>

            {props.currentPage !== "eventOrganizerDetails" &&
              props.currentPage !== "businessDetails" && (
                <button
                  type="button"
                  onClick={() => {
                    tToggle();
                  }}
                  className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
                  id="vertical-menu-btn"
                >
                  <i className="mdi mdi-menu"></i>
                </button>
              )}
          </div>
          <div className="d-flex">
            {props.currentPage !== "eventOrganizerDetails" &&
              props.currentPage !== "businessDetails" && <LanguageDropdown />}

            {props.currentPage !== "eventOrganizerDetails" &&
              props.currentPage !== "businessDetails" && (
                <>
                  {" "}
                  <div className="dropdown d-none d-lg-inline-block">
                    <button
                      type="button"
                      onClick={() => {
                        toggleFullscreen();
                      }}
                      className="btn header-item noti-icon waves-effect"
                      data-toggle="fullscreen"
                    >
                      <i className="mdi mdi-fullscreen font-size-24"></i>
                    </button>
                  </div>
                  {/* <NotificationDropdown /> */}
                </>
              )}

            <ProfileMenu />

            {props.currentPage !== "eventOrganizerDetails" &&
              props.currentPage !== "businessDetails" && (
                <div
                // onClick={() => {
                //   props.showRightSidebarAction(!props.showRightSidebar)
                // }}
                // className="dropdown d-inline-block"
                >
                  {/* <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <i className="mdi mdi-spin mdi-cog"></i>
                  </button> */}
                </div>
              )}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
