import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
// //Import Scrollbar

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();

  const [admin, setAdmin] = useState("admin");

  useEffect(() => {
    const accType = localStorage.getItem("acc_type");
    if (accType === "superadmin" || accType === "admin") {
      setAdmin("admin");
    } else if (accType === "business") {
      setAdmin("business");
    }

    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      {admin === "admin" ? (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {/* <li className="menu-title">{props.t("Main")} </li> */}
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <i className="mdi mdi-view-dashboard"></i>
                  {/* <span className="badge rounded-pill bg-primary float-end">
                  2
                </span> */}
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-city"></i>
                  <span>{props.t("Business")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/business" className=" waves-effect">
                      <span>{props.t("Business Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/hotels" className=" waves-effect">
                      <span>{props.t("Hotel Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature" className=" waves-effect">
                      <span>{props.t("Feature Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-account"></i>
                  <span>{props.t("Users")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/users" className=" waves-effect">
                      <span>{props.t("User Management")}</span>
                    </Link>
                  </li>


                </ul>
              </li>
              <li>
                <Link to="/BlogManagement" >
                  <i className="mdi mdi-city"></i>
                  <span>{props.t("Blog Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/offer" >
                  <i className="mdi mdi-tooltip-edit"></i>
                  <span>{props.t("Offer Management")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fad fa-burger-soda"></i>
                  <span>{props.t("Menu Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/food-category" className=" waves-effect">
                      <span>{props.t("Cuisine Category")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/menu-list" className=" waves-effect">
                      <span>{props.t("Cuisine")}</span>
                    </Link>
                  </li>



                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-map"></i>
                  <span>{props.t("Room Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/category" className=" waves-effect">
                      <span>{props.t("Room")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/booking" >
                  <i className="mdi mdi-tooltip-edit"></i>
                  <span>{props.t("Booking Management")}</span>
                </Link>

              </li>
              <li>
                <Link to="/contactUs" className=" waves-effect">
                  <i className="mdi mdi-account-box"></i>
                  <span>{props.t("Contact Us")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      ) : (
        <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {/* <li className="menu-title">{props.t("Main")} </li> */}
              <li>
                <Link to="/business-account/dashboard" className="waves-effect">
                  <i className="mdi mdi-view-dashboard"></i>
                  {/* <span className="badge rounded-pill bg-primary float-end">
                  2
                </span> */}
                  <span>{props.t("Dashboard")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-city"></i>
                  <span>{props.t("Business")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/business" className=" waves-effect">
                      <span>{props.t("Business Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/hotels" className=" waves-effect">
                      <span>{props.t("Hotel Management")}</span>
                    </Link>
                  </li>


                  <li>
                    <Link to="/music" className=" waves-effect">
                      <span>{props.t("Music Management")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/feature" className=" waves-effect">
                      <span>{props.t("Feature Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/food-category" className=" waves-effect">
                      <span>{props.t("Cuisine Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/ratings" className=" waves-effect">
                      <span>{props.t("Rating Management")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/follows" className=" waves-effect">
                      <span>{props.t("Follow Management")}</span>
                    </Link>
                  </li>


                </ul>
              </li>


              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-map"></i>
                  <span>{props.t("Offers")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">

                  <li>
                    <Link to="/specialOffer" className=" waves-effect">
                      <span>{props.t("Discount")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/specialOfferclaim" className=" waves-effect">
                      <span>{props.t("Discount Claim")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/business-account/vouchers"
                      className="waves-effect"
                    >
                      <span>{props.t("Deal Management")}</span>
                    </Link>
                  </li>

                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-tooltip-edit"></i>
                  <span>{props.t("Stories")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">

                  <li>
                    <Link to="/business-account/stories" className=" waves-effect">
                      <span>{props.t("Story Management")}</span>
                    </Link>
                  </li>

                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-camera-iris"></i>
                  <span>{props.t("Events")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>

                    <Link to="/events" className=" waves-effect">
                      <span>{props.t("Event Management")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>

          </div>
        </SimpleBar>
      )}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
