
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm } from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUpload from "../../components/Common/FileUpload";
export default function BlogCreated() {

  const [pageData, setPageData] = useState({
    title: "",
    description: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  const [selectedImage, setSelectedImage] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result)
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const CreateBlog = async (e) => {
    e.preventDefault();
    if (!pageData.title) {
      toast.error("Title is required");
      return;
    }
    if (!pageData.description) {
      toast.error("Please enter description");
      return;
    }
    // if(!selectedImage){
    //   toast.error("Please select image");
    //   return;
    // }

    setLoading(true);

    const formData = new FormData();

    const selectedImageForm = new FormData();
    selectedImageForm.append("image", selectedImage);
    setStartLoader(true);
    const selectedImageRes = await postRequestForm("image/upload", selectedImageForm);
    setStartLoader(false);

    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");
    }


    formData.append("title", pageData.title);
    formData.append("description", pageData.description);
    formData.append("metaTitle", pageData.metaTitle);
    formData.append("metaDescription", pageData.metaDescription);
    formData.append("tags", pageData.tags);
    formData.append("keywords", pageData.keywords);

    setLoading(true);
    const response = await postRequestForm("blog/add", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/BlogManagement");
      }, 3000);

    } else {
      toast.error(response.message);
    }

  };

  const updateValue = (index, value) => {
    const list = { ...pageData }
    list[index] = value
    setPageData(list)
  }


  return (
    <React.Fragment>

      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>BlogManagement</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">BlogManagement</li>:{" "}
              <li className="breadcrumb-item">
                Create Page
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      <Row>
        <Col lg={12}>
          <Card>

            {loading ?
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: '4rem',
                    width: '4rem',
                    position: "absolute",
                    left: "50%"
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
              :

              <CardBody>
                <h4 className="card-title"> Create Blog</h4>
                <Form onSubmit={CreateBlog}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Title</label>
                          <div className="col-sm-6">
                            <input type="text" onChange={(e) => updateValue('title', e.target.value)} name='title' className="form-control" />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Description</label>
                          <div className="col-sm-6">
                            {/* <textarea type="textarea" onChange={ (e) => updateValue('description', e.target.value) } name='description' className="form-control" id="horizontal-password-input" /> */}
                            <ReactQuill
                              style={{ height: "400px", width: "100%", marginBottom: "40px" }}
                              value={pageData?.description}
                              onChange={(e) => updateValue('description', e)}
                              modules={{
                                toolbar: [
                                  [{ header: [1, 2, 3, false] }],
                                  [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strike",
                                    "blockquote",
                                  ],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["link", "image", "video"],
                                  ["clean"],
                                ],
                              }}
                              formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "link",
                                "image",
                                "video",
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Meta Title</label>
                          <div className="col-sm-6">
                            <input type="text" onChange={(e) => updateValue('metaTitle', e.target.value)} name='title' className="form-control" />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Meta Description</label>
                          <div className="col-sm-6">
                            <textarea
                              name="metaDescription"
                              required
                              className="form-control"
                              id="metaDescription1"
                              rows="3"
                              onChange={(e) => updateValue('metaDescription', e.target.value)}
                              minLength={10}
                              maxLength={160}
                            ></textarea>




                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label"> Tags</label>
                          <div className="col-sm-6">
                            <input type="text" onChange={(e) => updateValue('tags', e.target.value)} name='title' className="form-control" />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label"> Keywords</label>
                          <div className="col-sm-6">
                            <input type="text" onChange={(e) => updateValue('keywords', e.target.value)} name='title' className="form-control" />
                          </div>
                        </div>







                        <div className="row mb-4">
                          <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Image</label>
                          <div className="col-sm-6">
                            <input type="file" accept="image/*" onChange={(e) => setSelectedImage(e.target.files[0])} name='image' className="form-control" id="horizontal-password-input" />
                            <br />
                            {previewImage && (
                              <img src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }} />
                            )}
                          </div>
                        </div>


                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button type="submit" className="btn btn-primary w-md">Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
