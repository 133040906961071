import React from "react";
import { Redirect } from "react-router-dom";
//Email
// Authentication related pages
import Login from "../pages/Authentication/Login";
import EventLogin from "../pages/Authentication/EventLogin";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserManagmentlist from "pages/UserManagement/UserManagmentlist";
import Updateuserdata from "pages/UserManagement/Updateuserdata";
import Userdetails from "pages/UserManagement/Userdetails.js";

import EventForgotPassword from "pages/Authentication/EventForgotPassword";
import AdminForgotPassword from "pages/Authentication/AdminForgotPassword";
import CategoryList from "pages/CategoryManagement/CategoryList";
import CreateCategory from "pages/CategoryManagement/CreateCategory";
import UpdateCategory from "pages/CategoryManagement/UpdateCategory";
import Categorydetails from "pages/CategoryManagement/Categorydetails";
import CreateUser from "pages/UserManagement/CreateUser";
import BusinessLogin from "pages/Authentication/BusinessLogin";
import BusinessForgotPassword from "pages/Authentication/BusinessForgotPassword";
import AdminProfileUpdate from "pages/AdminManagement/adminProfile";
import ManagementBlogList from "pages/BlogManagement/ManagementBlogList";
import BlogCreated from "pages/BlogManagement/BlogCreated";
import UpdatedBlog from "pages/BlogManagement/UpdatedBlog";

import BusinessManagmentlist from "pages/BusinessManagement/BusinessManagmentlist";
import BusinessManagmentDetail from "pages/BusinessManagement/Businessdetail";
import CreateBusiness from "pages/BusinessManagement/CreateBusiness";
import UpdateBusiness from "pages/BusinessManagement/UpdateBusiness";

import HotelManagmentlist from "pages/VenueManagement/VenueManagmentlist";
import HotelDetail from "pages/VenueManagement/Venuedetails";
import updateHotel from "pages/VenueManagement/UpdateVenue";

import MusicList from "pages/Music/MusicList";
import UpdateMusic from "pages/Music/UpdateMusic";
import CreateMusic from "pages/Music/CreateMusic";

import FeatureList from "pages/Features/FeatureList";
import UpdateFeature from "pages/Features/UpdateFeature";
import CreateFeature from "pages/Features/CreateFeature";

import CuisineManagmentlist from "pages/CuisineManagement/CuisineManagmentlist";
import UpdateCuisine from "pages/CuisineManagement/UpdateCuisine";

import BookingManagementList from "pages/BookingManagement/BookingManagementList";
import CategoryManagementlist from "pages/RoomManagement/CategoryManagementlist";
import CreateCategoryRoom from "pages/RoomManagement/CreateCategoryRoom";
import UpdatedCategoryManagement from "pages/RoomManagement/UpdatedCategoryManagement";
import ContactUsManagementlist from "pages/ContactUsManagement/ContactUsManagementlist";
import DiscountManagementlist from "../pages/DiscountManagement/DiscountManagmentlist";
import CreateDiscount from "pages/DiscountManagement/CreateDiscount";
import updateDiscount from "pages/DiscountManagement/UpdateDiscount";

import MenuManagementlist from "pages/MenuManagement/MenuManagmentlist";
import CreateMenu from "pages/MenuManagement/CreateMenu";
import UpdateMenu from "pages/MenuManagement/UpdateMenu";
import CreateCuisine from "pages/CuisineManagement/CreateCuisine";
import UpdatedPassword from "pages/AdminManagement/UpdatedPassword";





const userRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/BlogManagement", component: ManagementBlogList },
  { path: "/Blog/create", component: BlogCreated },
  { path: "/updateBlog/:id", component: UpdatedBlog },

// Booking management 

  { path: "/booking", component: BookingManagementList },
 

  // contact management 

  { path: "/contactUs", component: ContactUsManagementlist },




  { path: "/offer", component: DiscountManagementlist },
  { path: "/createOffer", component: CreateDiscount },
  { path: "/updateOffer/:id", component: updateDiscount },

// menu management 


{ path: "/menu-list", component: MenuManagementlist },
{ path: "/create-menu", component: CreateMenu},
{ path: "/updateMenu/:id", component: UpdateMenu},
// Room Management 

{ path: "/category", component: CategoryManagementlist },
{ path: "/createCategory", component: CreateCategoryRoom },
{ path: "/updateCategory/:id", component: UpdatedCategoryManagement },



  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
  { path: "/users", component: UserManagmentlist },
  { path: "/userdetails/:id", component: Userdetails },

  // admin profile
  { path: "/adminProfile", component: AdminProfileUpdate },
  { path: "/update/password", component: UpdatedPassword },


  // // Category List
  // { path: "/category", component: CategoryList },
  // { path: "/Createcategorydata", component: CreateCategory },
  // { path: "/Updatecategorydata/:id", component: UpdateCategory },
  // { path: "/categorydetails/:id", component: Categorydetails },


//bussiness
{ path: "/business", component: BusinessManagmentlist },
{ path: "/businessdetails/:id", component: BusinessManagmentDetail },
{ path: "/createBusiness", component: CreateBusiness },
{ path: "/updateBusiness/:id", component: UpdateBusiness },

//hotel
{ path: "/hotels", component: HotelManagmentlist },
{ path: "/hoteldetails/:id", component: HotelDetail },
{ path: "/updateHotel/:id", component: updateHotel },

//music
{ path: "/music", component: MusicList },
{ path: "/Updatemusicdata/:id", component: UpdateMusic },
{ path: "/Createmusicdata", component: CreateMusic },

//feature

{ path: "/feature", component: FeatureList },
{ path: "/Updatefeaturedata/:id", component: UpdateFeature },
{ path: "/Createfeaturedata", component: CreateFeature },

//food-category
{ path: "/food-category", component: CuisineManagmentlist },
{ path: "/update-food-category/:id", component: UpdateCuisine },
{ path: "/create-food-category", component: CreateCuisine },

{ path: "/bookig", component: BookingManagementList },

];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/business-account/login", component: Login },
  { path: "/forgotPassword", component: AdminForgotPassword },
  { path: "/eventlogin", component: EventLogin },
  { path: "/eventForgotPassword", component: EventForgotPassword },
  { path: "/businesslogin", component: BusinessLogin },
  { path: "/businessForgotPassword", component: BusinessForgotPassword },
];

export { userRoutes, authRoutes };
